import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public notifier: NotifierService;

  public loginForm : FormGroup;

  constructor(public fb : FormBuilder,
    public notifierService: NotifierService,
    public service : AppService, public router : Router, public spinner : NgxSpinnerService) {
      this.notifier = notifierService;
    }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
        username : ['' , Validators.required],
        password : ['' , Validators.required],
    });



   


  }


  public getData=(data)=>{
    this.spinner.show();
    let x = {
      username : this.loginForm.controls['username'].value,
      password : this.loginForm.controls['password'].value
    };

    this.service.login(x).subscribe((res:any)=>{
           console.log(res);
           if(res.status === 'True')
           {
             this.spinner.hide();
             localStorage.setItem('usernameBellTell' , this.loginForm.controls['username'].value)
             localStorage.setItem('passwordBellTell' , this.loginForm.controls['password'].value)
             this.router.navigate(['/dashboard']);
             this.loginForm.reset();
           }
           else
           {
             this.spinner.hide();
             this.notifier.notify("error" , "Username or password not True!");
           }

    });



  }

}
