import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'cil-home',
  },
  {
    name: 'Advertisement',
    url: '/advertisement',
    icon: 'cil-lan'
  },
  {
    name: 'Costing',
    url: '/costing',
    icon: 'cil-dollar'
  },
  {
    name: 'Categories',
    url: '/categories',
    icon: 'cil-lan'
  },
  {
    name: 'End-Users',
    url: '/users',
    icon: 'cil-people'
  },
  {
    name: 'Wing',
    url: '/wing',
    icon: 'cil-send',
  },
  {
    name: 'Dealer',
    url: '/dealer',
    icon: 'cil-people',
  },
  {
    name: 'Experts',
    url: '/experts',
    icon: 'cil-contact',
  },
  {
    name: 'Male Expert',
    url: '/male-experts',
    icon: 'cil-user'
  },
  {
    name: 'Female Expert',
    url: '/female-experts',
    icon: 'cil-user-female',
  },
  {
    name: 'Employee',
    url: '/employee',
    icon: 'cil-wc',
  },
  {
    name: 'Company',
    url: '/company',
    icon: 'cil-wc',
  },
  {
    name: 'Super Admin',
    url: '/super-admin',
    icon: 'cil-devices',

  },

  {
    name: 'Control Admin',
    url: '/control-admin',
    icon: 'cil-compass',

  },
  {
    name: 'Post',
    url: '/post',
    icon: 'cil-speedometer',
  },
  {
    name: 'Top up',
    url: '/topup',
    icon: 'cil-user-follow',
  },
  {
    name: 'ADME',
    url: '/addMe',
    icon: 'cil-user-follow',
  },
  {
    name: 'Permissions',
    url: '/permissions',
    icon: 'cil-spreadsheet',
  },
  {
    name: 'WallBux',
    url: '/master-wallbux',
    icon: 'icon-layers',
  },
  {
    name: 'Add Admin',
    url: '/add-admin',
    icon: 'cil-user-follow',
  }
];
