import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { AddAdminComponent } from './views/add-admin/add-admin.component';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'add-admin',
    component: AddAdminComponent,
    data: {
      title: 'Add Admin'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
     {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate:  [AuthGuard],
      },
      {
        path : 'categories',
        loadChildren : () => import('./views/categories/categories.module').then(m => m.CategoriesModule),
        canActivate:  [AuthGuard],
      },
      {
        path : 'users',
        loadChildren : () => import('./views/users/users.module').then(m => m.UsersModule),
        canActivate:  [AuthGuard],
      },
      {
        path: 'wing',
        loadChildren: () => import('./views/wing/wing.module').then(m => m.WingModule),
        canActivate:  [AuthGuard],
      },
      {
        path : 'experts',
        loadChildren : () => import('./views/experts/experts.module').then(m => m.ExpertsModule),
        canActivate:  [AuthGuard],
      },
      {
        path : 'male-experts',
        loadChildren : () => import('./views/male-experts/male-experts.module').then(m => m.MaleExpertsModule),
        canActivate:  [AuthGuard],
      },
      {
        path : 'female-experts',
        loadChildren : () => import('./views/female-experts/female-experts.module').then(m => m.FemaleExpertsModule),
        canActivate:  [AuthGuard],
      },
      {
        path : 'employee',
        loadChildren : () => import('./views/employee/employee.module').then(m => m.EmployeeModule),
        canActivate:  [AuthGuard],
      },
      {
        path : 'super-admin',
        loadChildren : () => import('./views/super-admin/super-admin.module').then(m => m.SuperAdminModule),
        canActivate:  [AuthGuard],
      },
      {
        path : 'control-admin',
        loadChildren : () => import('./views/control-admin/control-admin.module').then(m => m.ControlAdminModule),
        canActivate:  [AuthGuard],
      },
      {
        path : 'post',
        loadChildren : () => import('./views/post/post.module').then(m => m.PostModule),
        canActivate:  [AuthGuard],
      },
      {
        path : 'addMe',
        loadChildren : () => import('./views/add-me/add-me.module').then(m => m.AddMeModule),
        canActivate:  [AuthGuard],
      },
      {
        path : 'topup',
        loadChildren : () => import('./views/top-up/top-up.module').then(m => m.TopUpModule),
        canActivate:  [AuthGuard],
      },
      {
        path : 'permissions',
        loadChildren : () => import('./views/permissions/permissions.module').then(m => m.PermissionsModule),
        canActivate:  [AuthGuard],
      },
      {
        path : 'master-wallbux',
        loadChildren : () => import('./views/master-wallbux/master-wallbux.module').then(m => m.MasterWallbuxModule),
        canActivate:  [AuthGuard],
      },
      {
        path : 'advertisement',
        loadChildren : () => import('./views/advertisement/advertisement.module').then(m => m.AdvertisementModule),
        canActivate:  [AuthGuard],
      },
      {
        path : 'costing',
        loadChildren : () => import('./views/costing/costing.module').then(m => m.CostingModule),
        canActivate:  [AuthGuard],
      },
      {
        path : 'dealer',
        loadChildren : () => import('./views/dealer/dealer.module').then(m => m.DealerModule),
        canActivate:  [AuthGuard],
      },
      {
        path : 'company',
        loadChildren : () => import('./views/company/company.module').then(m => m.CompanyModule),
        canActivate:  [AuthGuard],
      },
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
